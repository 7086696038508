define(['lodash', 'prop-types', 'componentsCore', 'coreUtils', 'pmrpc', 'santa-components', 'backgroundCommon/mixins/videoPlayerMixin'], function (_, PropTypes, componentsCore, coreUtils, rpc, santaComponents, videoPlayerMixin) {
    'use strict';

    const consts = coreUtils.mediaConsts;

    /**
     *
     * @param {{
     *  iframeUrl: string,
     *  apiId: string,
     *  displayName?: string
     * }} options
     * @returns {{}}
     */
    function getIframeVideoComponent({iframeUrl, apiId, displayName = 'iframeVideo'}) {
        /**
         * @class components.iframeVideo
         * @extends {core.skinBasedComp}
         */
        return {
            displayName,
            mixins: [videoPlayerMixin, componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],
            propTypes: _.defaults(
                {
                    compData: PropTypes.object.isRequired,
                    compProp: PropTypes.object,

                    isPlayingAllowed: PropTypes.bool,
                    notifyMediaState: PropTypes.func.isRequired,
                    setMediaAPI: PropTypes.func.isRequired,

                    staticVideoUrl: santaComponents.santaTypesDefinitions.ServiceTopology.staticVideoUrl
                },
                santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes)
            ),

            // Lifecycle

            getInitialState() {
                this.player = null;
                this.eventHandlers = [];
                return {
                    loadVideo: false,
                    showVideo: false
                };
            },

            componentDidMount() {
                this.props.setMediaAPI(this.mediaAPI);
                this.props.notifyMediaState({
                    type: consts.eventTypes.MOUNT,
                    playbackState: consts.playbackTypes.LOADING
                });
                if (this.props.compProp.autoplay && this.props.isPlayingAllowed) {
                    this.play();
                }
            },

            componentDidUpdate() {
                this.setRate(this.props.compData.playbackSpeed || 1);
            },

            componentWillUnmount() {
                this.props.setMediaAPI(null);
                this.removeVideoSecurely();
            },

            // Internals
            getUrl(mediaData, quality) {
                const baseUrl = 'https://video.wixstatic.com/'; //this.props.staticVideoUrl;

                if (!quality) {
                    return '';
                }
                const targetQuality = _.find(mediaData.qualities, {quality});

                //preffer video url from new design/data structure
                if (targetQuality.url) {
                    return coreUtils.urlUtils.joinURL(baseUrl, targetQuality.url);
                }

                //build uri from videoId
                return coreUtils.urlUtils.joinURL(baseUrl, mediaData.videoId, quality, 'mp4', 'file.mp4');
            },

            onVideoFrameReady() {
                const url = this.getUrl(this.props.compData, this.props.mediaQuality);
                rpc.api.request(apiId, {target: this.refs.video})
                    .then(api => {
                        this.player = api;
                        this.player.set(url, {
                            loop: false,
                            mute: this.props.compProp.mute || this.props.compData.hasAudio === false,
                            autoplay: true,
                            rate: this.props.compData.playbackSpeed || 1,
                            preload: this.props.compData.preload || 'none'
                        }, this.onVideoEvent);
                        this.addVideoEventListener('timeupdate', this.handlePosterVisibilityOnce);

                        this.addVideoEventListener('ended', this.onPlayEnded);
                        this.addVideoEventListener('error', this.onError);
                        this.addVideoEventListener('loadstart', this.onLoadStart);
                        // this.addVideoEventListener('loadeddata', this.onLoadedData);
                        // this.addVideoEventListener('canplay', this.onCanPlay);
                        this.addVideoEventListener('durationchange', this.onDurationChange);
                        this.addVideoEventListener('pause', this.onPause);
                        this.addVideoEventListener('play', this.onPlay);
                        this.addVideoEventListener('progress', this.onProgress);
                        this.addVideoEventListener('ratechange', this.onRateChange);
                        this.addVideoEventListener('seeked', this.onSeekEnd);
                        this.addVideoEventListener('seeking', this.onSeekStart);
                        this.addVideoEventListener('stalled', this.onStalled);
                        this.addVideoEventListener('timeupdate', this.onTimeUpdate);
                        this.addVideoEventListener('volumechange', this.onVolumeChange);
                    })
                    .catch(reason => {
                        console.log('Video API not loaded.', reason); //eslint-disable-line no-console
                    });
            },

            /**
             * Add an event listener for a video event
             * @param {string} eventType
             * @param {function} handler
             * @returns {boolean}
             */
            addVideoEventListener(eventType, handler) {
                if (!_.find(this.eventHandlers, {eventType, handler})) {
                    this.eventHandlers.push({eventType, handler});
                    return true;
                }
            },

            /**
             * Remove an event listener for a video event
             * @param {string} eventType
             * @param {function} handler
             * @returns {boolean}
             */
            removeVideoEventListener(eventType, handler) {
                if (_.find(this.eventHandlers, {eventType, handler})) {
                    this.eventHandlers = _.reject(this.eventHandlers, {eventType, handler});
                    return true;
                }
            },

            /**
             * Invoke handlers registered to event.type
             * @param {object} event
             */
            onVideoEvent(event) {
                const handlers = _.filter(this.eventHandlers, {eventType: event.type});

                _.forEach(handlers, function (handlerDef) {
                    handlerDef.handler(event);
                });
            },

            /**
             * Hide the poster when video playback starts
             * NOTE: this event removes itself once it meets the conditions which invokes it
             */
            handlePosterVisibilityOnce(event) {
                if (event.currentTime > 0) {
                    this.removeVideoEventListener('timeupdate', this.handlePosterVisibilityOnce);
                    this.setState({
                        showVideo: true
                    });
                }
            },

            resetPosterState() {
                if (this.state.showVideo) {
                    this.setState({
                        showVideo: false
                    });
                    this.addVideoEventListener('timeupdate', this.handlePosterVisibilityOnce);
                }
            },

            /**
             * To prevent weird browser bugs, remove the video "securely" -
             * Remove every video source and reload the video object with empty sources
             */
            removeVideoSecurely() {
                if (this.player) {
                    this.eventHandlers = [];
                    this.player.reset();
                }
            },

            // API

            // mediaAPI() moved to videoPlayerMixin.

            /**
             * Play the video
             */
            play() {
                if (this.player) {
                    this.player.play();
                } else {
                    this.setState({
                        loadVideo: true
                    });
                }
            },

            /**
             * Pause the video
             */
            pause() {
                if (this.player) {
                    this.player.pause();
                }
            },

            /**
             * Stop the video (Pause and set play head to 0)
             */
            stop() {
                if (this.player) {
                    this.pause();
                    this.seek(0);
                    this.resetPosterState();
                }
            },

            /**
             * Set the volume
             * @param {number} volume - min: 0, max: 1
             */
            setVolume(volume) {
                if (this.player) {
                    this.player.setVolume(volume);
                }
            },

            /**
             * Mute the video
             */
            mute() {
                if (this.player) {
                    this.player.setMute(true);
                }
            },

            /**
             * Un-mute the video
             */
            unMute() {
                if (this.player) {
                    this.player.setMute(false);
                }
            },

            /**
             * Seek the video
             * @param {number} time in seconds - min: 0, max: video duration
             */
            seek(time) {
                if (this.player) {
                    this.player.seek(time);
                }
            },

            /**
             * Change the video speed
             * @param {number} speed the video speed, min: 0
             */
            setRate(speed) {
                if (this.player) {
                    this.player.setRate(speed);
                }
            },

            // Event Listeners

            /**
             * On the first time the video element loads
             * Happens only once per load() video event
             */
            onLoadStart(event) {
                this.props.notifyMediaState({
                    type: consts.eventTypes.LOAD,
                    playbackState: consts.playbackTypes.READY,
                    volume: event.volume,
                    muted: event.muted,
                    looped: this.props.compProp.loop,
                    currentTime: event.currentTime,
                    progress: 0
                });
            },

            onDurationChange(event) {
                this.props.notifyMediaState({
                    type: consts.eventTypes.LOAD,
                    duration: event.duration
                });
            },

            /**
             * On every play head time update
             */
            onTimeUpdate(event) {
                this.props.notifyMediaState({
                    type: consts.eventTypes.TIME_UPDATE,
                    currentTime: event.currentTime
                });
            },

            /**
             * when the video reaches the end of its play head and is not looped
             */
            onPlayEnded() {
                this.props.notifyMediaState({
                    type: consts.eventTypes.PLAYSTATE,
                    playbackState: consts.playbackTypes.PLAY_ENDED
                });
            },

            /**
             * On every time the playback changes from paused to playing
             */
            onPlay() {
                this.props.notifyMediaState({
                    type: consts.eventTypes.PLAYSTATE,
                    playbackState: consts.playbackTypes.PLAYING
                });
            },

            /**
             * On every time the playback changes from playing to paused
             */
            onPause() {
                this.props.notifyMediaState({
                    type: consts.eventTypes.PLAYSTATE,
                    playbackState: consts.playbackTypes.PAUSED
                });
            },

            /**
             * On error events
             * @param {Event} event
             */
            onError(event) {
                if (event.networkState === event.NETWORK_NO_SOURCE) {
                    this.props.notifyMediaState({
                        type: consts.eventTypes.ERROR,
                        error: consts.errorTypes.NO_VIDEO_FOUND
                    });
                } else {
                    this.props.notifyMediaState({
                        type: consts.eventTypes.ERROR,
                        error: consts.errorTypes.VIDEO_GENERAL_ERROR
                    });
                }
            },

            /**
             * Stalled video event
             * Safari throw stalled event on 404 http error and not the regular video error event
             * @param event
             */
            onStalled(event) {
                if (event.readyState === event.HAVE_NOTHING) {
                    this.props.notifyMediaState({
                        type: consts.eventTypes.ERROR,
                        error: consts.errorTypes.NO_VIDEO_FOUND
                    });
                }
            },

            /**
             * On every time the buffer length changes
             */
            onProgress(event) {
                this.props.notifyMediaState({
                    type: consts.eventTypes.PROGRESS,
                    progress: event.progress
                });
            },

            /**
             * Notify seek started
             */
            onSeekStart() {
                this.props.notifyMediaState({
                    type: consts.eventTypes.PLAYSTATE,
                    playbackState: consts.playbackTypes.SEEKING
                });
            },

            /**
             * Notify seek had ended
             */
            onSeekEnd() {
                this.props.notifyMediaState({
                    type: consts.eventTypes.PLAYSTATE,
                    playbackState: consts.playbackTypes.SEEKING_ENDED
                });
            },

            /**
             * On every volume change
             */
            onVolumeChange(event) {
                this.props.notifyMediaState({
                    type: consts.eventTypes.VOLUME,
                    volume: event.volume,
                    muted: event.muted
                });
            },

            /**
             * On every video speed change
             */
            onRateChange(event) {
                this.props.notifyMediaState({
                    type: consts.eventTypes.RATE,
                    playbackRate: event.playbackRate
                });
            },

            // Render

            getVideo() {
                return santaComponents.utils.createReactElement('iframe', {
                    ref: 'video',
                    id: `${this.props.id}video`,
                    frameBorder: 0,
                    allowFullScreen: true,
                    'data-src': this.state.loadVideo ? iframeUrl : 'about:blank',
                    onLoad: this.onVideoFrameReady
                });
            },

            getSkinProperties() {
                const outerStyle = {
                    width: '100%'
                };
                const extraProps = {
                    containerWidth: this.props.posterWidth || 0,
                    containerHeight: this.props.posterHeight || 0,
                    shouldRenderSrc: this.props.shouldRenderSrc
                };
                const poster = this.getPosterImageComp(this.props.compData.posterImageRef, this.state.showVideo, extraProps);

                return {
                    '': {
                        'data-quality': this.props.mediaQuality,
                        'data-player-type': 'iframe',
                        style: outerStyle
                    },
                    'video': this.getVideo(),
                    poster
                };
            }
        };
    }

    return {
        getIframeVideoComponent
    };
});
